ex-data {
    display: block;
    flex-wrap: wrap;

    .ex-data-label {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    ex-data-value {
        flex: 1;
    }

    .ex-data-value-with-forage-content {
        margin-top: 0;
    }
}

.ex-data-libelle--droite {
    flex-direction: row-reverse;
    position: relative;

    .ex-data-label {
        max-width: 100%;
    }

    .ex-data-value {
        flex: initial;
        flex-shrink: 0;
        padding-right: 8px;
        padding-left: 0 !important;
    }

    .ex-data-value--action {
        position: absolute;
        right: 0;
    }
}

.ex-data-libelle--valeur {
    flex-direction: row-reverse;
    position: relative;
    justify-content: flex-end;

    .ex-data-value {
        text-align: right;
        width: 30%;
        max-width: 30%;
        min-width: 30%;
        padding-right: 8px;
    }

    .ex-data-label {
        color: $black !important;
        padding-left: 8px;
    }

    &.ex-data--secondaire {
        .ex-data-label {
            font-size: 12px !important;
            color: $grey-medium !important;
        }
    }
}

.ex-data-bottom-padding {
    padding-bottom: 8px;
}

.ex-data-top-padding {
    padding-top: 8px;
}

.ex-data, .ex-data-ligne {
    flex-basis: 200px;
}

ex-data-valeur {
    // Vieille propriété
    word-wrap: break-word;
    // Nouvelle propriété
    overflow-wrap: break-word;
    display: block;
}

.ex-data-label:empty {
    display: none;
}

.ex-data--secondaire:not(.ex-data-libelle--valeur) {
    @extend .ex-couleur-texte-second;

    .ex-data-value-content {
        font-size: 12px !important;
    }

    md-icon {
        @extend .ex-couleur-texte-second;
    }
}

.ex-data--alignment-droite {
    .ex-data-value-content {
        padding-right: 16px;
    }
}
.ex-data-breakLine {
    white-space: pre-line;
}
