/*
 * ------------------------------
 * Padding et margin
 * ------------------------------
 */
// Les dimensions suivantes suivent les normes de Material Design, mais ne sont pas incluses dans Angular Material
$layout-spacing-sizes: 0 4 8 12 16 24 32 40 48 ;
$layout-sides: (t: top, r: right, b: bottom, l: left);
@each $size in $layout-spacing-sizes {
    @each $shortSide, $side in $layout-sides {
        .m-#{$shortSide}-#{$size} {
            margin-#{$side}: #{$size}px !important;
        }
        .m-#{$shortSide}-#{$size}-neg {
            margin-#{$side}: -#{$size}px !important;
        }
        .p-#{$shortSide}-#{$size} {
            padding-#{$side}: #{$size}px !important;
        }
    }

    .m-#{$size} {
        margin: #{$size}px !important;
    }
    .m-#{$size}-neg {
        margin: -#{$size}px !important;
    }

    .p-#{$size} {
        padding: #{$size}px !important;
    }

    .m-h-#{$size} {
        margin-left: #{$size}px !important;
        margin-right: #{$size}px !important;
    }

    .m-h-#{$size}-neg {
        margin-left: -#{$size}px !important;
        margin-right: -#{$size}px !important;
    }

    .m-v-#{$size} {
        margin-top: #{$size}px !important;
        margin-bottom: #{$size}px !important;
    }

    .m-v-#{$size}-neg {
        margin-top: -#{$size}px !important;
        margin-bottom: -#{$size}px !important;
    }

    .p-h-#{$size} {
        padding-left: #{$size}px !important;
        padding-right: #{$size}px !important;
    }

    .p-v-#{$size} {
        padding-top: #{$size}px !important;
        padding-bottom: #{$size}px !important;
    }
}

$tab-spacing-sizes: 0 1 2 3 4 5;
@each $size in $tab-spacing-sizes {

    .tab-#{$size} {
        margin-left: #{16*$size}px !important;
    }
}

.ex-full-width {
    width: 100%;
    box-sizing: border-box;
}
.ex-full-height {
    height: 100%;
    box-sizing: border-box;
}

.ex-border-box {
    box-sizing: border-box;
}

.ex-align-right {
    text-align: right;
}

.ex-align-left {
    text-align: left;
}

.ex-align-center {
    text-align: center;
}

.ex-rounded-corners {
    border-radius: 4px;
}

.ex-texte-gras * {
    font-weight: 500;
}

md-icon.ex-large-icon {
    font-size: 48px;
    width: 48px;
    height: 48px;
    margin: auto;
    margin-left: 8px !important;
}

//Firefox applique du padding automatiquement ce qui désaligner le layout
button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.md-tooltip {
    font-size: 12px !important;
    border-radius: 5px;
    padding: 5px;
}